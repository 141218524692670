import { Grid, Layers, Headphones, Home, Link, List, Phone, Music, Book, Monitor, Bell } from "react-feather";


const MENUITEMS = [
  {
    title: "Dashboard",
    icon: <Home />,
    url: `${process.env.PUBLIC_URL}/Dashboard`
  },
  {
    title: "Taskboard",
    icon: <Monitor />,
    url: `${process.env.PUBLIC_URL}/Taskboard`
  },
  {
    title: "Tasks",
    icon: <List />,
    url: `${process.env.PUBLIC_URL}/Tasks`
  },
  {
    title: "CRM",
    icon: <Grid />,
    menu: [
      {
        title: "Leads",
        url: `${process.env.PUBLIC_URL}/Leads`,
        type: "link",
        icon: <Link />
      },
      {
        title: "Prospects",
        url: `${process.env.PUBLIC_URL}/Prospects`,
        type: "link",
      },
      {
        title: "Quotes",
        url: `${process.env.PUBLIC_URL}/Quotes`,
        type: "link",
      },
      {
        title: "Orders",
        url: `${process.env.PUBLIC_URL}/Orders`,
        type: "link",
      },
      {
        title: "Invoices",
        url: `${process.env.PUBLIC_URL}/Invoices`,
        type: "link",
      },
      {
        title: "Recovery",
        url: `${process.env.PUBLIC_URL}/Recovery`,
        type: "link",
      },
      {
        title: "Projects",
        url: `${process.env.PUBLIC_URL}/Projects`,
        type: "link",
      },
      {
        title: "Contract",
        url: `${process.env.PUBLIC_URL}/Subscription`,
        type: "link",
      },
      {
        title: "Attendance",
        url: `${process.env.PUBLIC_URL}/Attendance`,
        type: "link",
      },
    ],
  },
  {
    title: "Master",
    icon: <Layers />,
    menu: [
       {
        title: "Company",
        url: `${process.env.PUBLIC_URL}/Company`,
        type: "link",
      },
      {
        title: "Branch",
        url: `${process.env.PUBLIC_URL}/Branch`,
        type: "link",
      },
      {
        title: "Customer",
        url: `${process.env.PUBLIC_URL}/Customer`,
        type: "link",
      },
      {
        title: "Product",
        url: `${process.env.PUBLIC_URL}/Product`,
        type: "link",
      },
      {
        title: "Category",
        url: `${process.env.PUBLIC_URL}/Category`,
        type: "link",
      },
      {
        title: "SubCategory",
        url: `${process.env.PUBLIC_URL}/SubCategory`,
        type: "link",
      },
      {
        title: "Unit",
        url: `${process.env.PUBLIC_URL}/Unit`,
        type: "link",
      },
      {
        title: "Source",
        url: `${process.env.PUBLIC_URL}/Source`,
        type: "link",
      },
      {
        title: "QuatationName",
        url: `${process.env.PUBLIC_URL}/QuatationName`,
        type: "link",
      },
      {
        title: "Project Type",
        url: `${process.env.PUBLIC_URL}/Type`,
        type: "link",
      },
      {
        title: "User",
        url: `${process.env.PUBLIC_URL}/User`,
        type: "link",
      },
      {
        title: "Role",
        url: `${process.env.PUBLIC_URL}/Role`,
        type: "link",
      },
      {
        title: "RolesResponsibility",
        url: `${process.env.PUBLIC_URL}/RolesResponsibility`,
        type: "link",
      },
      {
        title: "Status",
        url: `${process.env.PUBLIC_URL}/Status`,
        type: "link",
      },
      {
        title: "Template",
        url: `${process.env.PUBLIC_URL}/Template`,
        type: "link",
      },
      {
        title: "Country",
        url: `${process.env.PUBLIC_URL}/Country`,
        type: "link",
      },
      {
        title: "State",
        url: `${process.env.PUBLIC_URL}/State`,
        type: "link",
      },
      {
        title: "City",
        url: `${process.env.PUBLIC_URL}/City`,
        type: "link",
      },
      {
        title: "Icon",
        url: `${process.env.PUBLIC_URL}/Icon`,
        type: "link",
      },
      {
        title: "MailAddress",
        url: `${process.env.PUBLIC_URL}/MailAddress`,
        type: "link",
      },
      {
        title: "ApplicationSetting",
        url: `${process.env.PUBLIC_URL}/ApplicationSetting`,
        type: "link",
      },
    ],
  },
  {
    title: "Support",
    icon: <Headphones />,
    url: `${process.env.PUBLIC_URL}/Support`
  },
  {
    title: "Notification",
    icon: <Bell />,
    url: `${process.env.PUBLIC_URL}/Notification`
  }

];

export default MENUITEMS;
